.deskTopWrapper {
  background: url(../../../assets/bg.png);
  background-size: cover; /* or 'contain' based on your preference */
  background-repeat: no-repeat;
  height: 100vh;
  padding: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: -29px;
}
.deskTopContent {
  margin-top: 130px;
}
.responsiveWrapper {
  display: none;
}
.mobileBG {
  display: none;
}
@media screen and (max-width: 860px) {
  .deskTopWrapper {
    display: none;
  }
  .responsiveWrapper {
    padding: 6%;
    margin-top: 88px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .mobileBG {
    display: flex;
  }
}
