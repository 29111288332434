.outer {
  min-height: 100vh;
  background-color: #222629;
}
.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 20px 20px 20px;
}
.footer {
  background-color: #ffe000;
  color: black;
  padding: 20px;
  width: 100%;
  position: sticky;
  bottom: 0;
  margin-right: 0;
  max-width: 100% !important;
}
