$navbarColor: white;

.wrapper {
  margin-top: 1.5%;
  display: flex;
  justify-content: center;
}
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  height: 88px;
  background-color: $navbarColor;
  padding: 23px 30px 23px 30px;
  border-radius: 23px;
  position: fixed;
  z-index: 100;
}
.visible {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

.hidden {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}
.desktopMenu {
  display: flex;
  align-items: center;
  gap: 54px;
  font-size: 15px;
  font-weight: 500;
  color: black !important;
}
.contactBtn {
  font-size: 15px;
  font-weight: 500;
  color: black;
  background-color: #ffe000;

  border-radius: 23px;
  padding: 10px 24px 10px 24px;
}
.ham {
  color: black !important;
}
.navlink {
  color: black;
  transition: all 0.2s;
  &:hover {
    font-weight: 500;
  }
}
.menuList.mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 5em;
  left: 0;
  width: 100%; /* This ensures the menu spans across the screen */
  background-color: $navbarColor;
  height: fit-content;
  z-index: 100000;

  /* To hide by default */
  padding: 0;
  height: 0;
  overflow-y: hidden;

  transition: all 0.1s;

  .navlink {
    display: inline-block;
    padding-block: 1em;
    width: 10em;
    text-align: center;
  }

  .deskTopActive {
    padding-bottom: 3px;
    border-bottom: 1px solid #ffe000;
  }

  .mobActive {
    color: #ffe000;
  }

  &.open {
    padding-block: 1em;
    height: 28em;
    overflow-y: hidden;
  }
  .contactBtn {
    font-size: 15px;
    font-weight: 600;
    color: #ffc700 !important;
    background-color: transparent;
    border: 1px solid #ffc700;
    border-radius: 23px;
    padding: 10px 24px 10px 24px;
  }
}
@media screen and (max-width: 1100px) {
  nav {
    border-radius: 0px;
    width: 100%;
  }
}
