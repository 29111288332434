.javaText {
  background: white;
  padding: 3px 6px;
  font-weight: 900;
  font-size: 20px;
  margin-right: 5px;
  color: black;
}

@media (max-width: 768px) {
  .javaText {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .javaText {
    font-size: 14px;
  }
}
