.wrapper {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 100px;
}
.container {
  width: 85%;
}

.getInTouch {
  background-color: #fff399;
  height: 451px;
  background-image: url("../../assets/contactbg.png");
}
