.wrapper {
  width: 95vw;
}
.smallCard {
  background-color: #1b1b1b;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 8px 8px 8px 8px;
  border-radius: 5px 5px 5px 5px;
  padding: 20px;
  display: flex;

  flex-wrap: wrap;

  align-content: flex-start;
}
.popInOut {
  animation: popInOutAnimation 1s infinite;
}

@keyframes popInOutAnimation {
  0% {
    transform: scale(0.98);
  }
  50% {
    transform: scale(0.94);
  }
  100% {
    transform: scale(0.98);
  }
}

.popInOut:hover {
  animation-play-state: paused;
}
.suneeshCard {
  margin: 10px 10px 10px 10px;
  padding: 15px 10px 15px 10px;
  background-color: #1b1b1b;
  border-radius: 10px 10px 10px 10px;
}
.suneeshImg {
  height: 250px;
}
