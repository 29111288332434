.wrapper {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 100px;
}
.container {
  width: 85%;
}
.aboutContainer {
  height: 100vh;
  width: 100vw;
  background-color: aquamarine;
}
.aboutImg {
  object-fit: cover;
}
